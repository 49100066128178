import React, { useState } from 'react';
import {
  HUD,
  SettingsPanel,
  ToC,
  HUDBookmarks,
  useFeature,
} from '@ole-ui/ole-ui-components';
import { Link } from 'react-mobx-router5';
import { useTranslation } from 'react-i18next';
import useMobxStores from 'hooks/useMobxStores';
import { observer } from 'mobx-react';
import SVG from 'react-inlinesvg';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';

import BookmarksIconFilled from 'img/icons/bookmarks-icon-filled.svg';
import BookmarksIcon from 'img/icons/bookmarks-icon.svg';

import VideoIcon from 'img/icons/tv-show-icon.svg';
import VideoIconFilled from 'img/icons/tv-show-icon-filled.svg';

import FeedbackIcon from 'img/icons/feedback-icon.svg';
import FeedbackIconFilled from 'img/icons/feedback-icon-filled.svg';

import BookshelfIcon from 'img/icons/bookshelf-icon.svg';

import SettingsIconFilled from 'img/icons/settings-icon-filled.svg';
import SettingsIcon from 'img/icons/settings-icon.svg';

import useAutoScrollToPageVideo from './hooks/useAutoScrollToPageVideo';
import CourseStore from './store';

type Props = {
  courseStore: CourseStore;
  onDirectionChange: (direction: 'next' | 'prev') => void;
  isVideoPlayerEnabled: boolean;
  onVideoPlayerChange: (isEnabled: boolean) => void;
};

const CourseHUD = (props: Props) => {
  const {
    courseStore,
    onDirectionChange,
    isVideoPlayerEnabled,
    onVideoPlayerChange,
  } = props;
  const { t } = useTranslation();
  const { routerStore, eventStore } = useMobxStores();
  const { route } = routerStore;
  const [activeHUDPanel, setActiveHUDPanel] = useState<string | null>(null);

  const isBookshelfEnabled = useFeature({
    section: 'course',
    flagName: 'bookshelf_button_active',
    defaultFlagValue: false,
  });

  useAutoScrollToPageVideo(isVideoPlayerEnabled);

  const {
    title,
    page,
    pageSlug,
    previousPageTag,
    courseTotalProgress,
    progressStructure,
    courseStructure,
    bookmarkList,
    canShowVideoPlayer,
    canShowBookshelf,
    isFreeToolbar,
  } = courseStore;

  const { next_tag: nextPageTag } = page ?? {};

  const sections =
    courseStructure?.filter((item) => item.type === 'section') || [];

  const currentPageNumber =
    (sections.findIndex((item) => item.page_tag === pageSlug) || 0) + 1;

  const totalPageNumber = sections.length;

  const bookmarks = bookmarkList.map((bookmark) => ({
    name: bookmark.title,
    id: bookmark.section,
  }));

  const isFeedbackDrawerOpen =
    eventStore.isDrawerActive === 'feedback' && eventStore.isDrawerOpen;

  return (
    <HUD
      key="course-hud"
      className="hud-course-view__hud pf-v5-theme-dark"
      localStorageKeyPrefix="course-hud"
      onDirectionChange={onDirectionChange as any}
      position="sticky"
      itemMaxHeightOffset={0}
      progress={courseTotalProgress}
      progressBarProps={{
        'aria-label': t('Course progress'),
      }}
      activeItem={activeHUDPanel}
      onActiveItemChange={(activeItem: string | null) => {
        setActiveHUDPanel(activeItem);
      }}
    >
      <HUD.MainItem
        hasPadding
        isResizable
        minHeight={200}
        label={page?.title || ''}
        buttonProps={{
          'aria-label': t('Toggle Table of Contents panel'),
          'data-analytics-id': `hud-toc-ole-lp`,
        }}
      >
        <ToC
          courseTitle={title}
          structure={courseStructure}
          progressStructure={progressStructure}
          renderLink={(structureItem) => (
            <Link
              href
              routeName="courses:page"
              routeParams={{
                course: (route as any).params.course, // eg. 'le101-1.0' or 'rh124-9.0'
                page: structureItem.page_tag, // eg. 'ch02s03' (legacy) or 'accessing-the-command-line-using-the-desktop' (unique universal id for pages)
              }}
              onClick={() => {
                if (structureItem.page_tag !== pageSlug) {
                  setActiveHUDPanel(null);
                }
              }}
              data-analytics-id="toc-link-ole-lp"
            >
              {structureItem.title}
            </Link>
          )}
          currentPageUrl={(route as any).params.page}
        />
      </HUD.MainItem>
      <HUD.Navigation
        currentPage={currentPageNumber}
        totalPages={totalPageNumber}
        onNavigate={(hudDirection) => {
          if (hudDirection === 'next') {
            routerStore.navigate('courses:page', {
              course: (route as any).params.course,
              page: nextPageTag || (route as any).params.course,
            });
          } else if (hudDirection === 'prev') {
            routerStore.navigate('courses:page', {
              course: (route as any).params.course,
              page: previousPageTag || (route as any).params.course,
            });
          }
        }}
        prevButtonProps={{
          'aria-label': t('Go to previous page'),
          'data-analytics-id': `top-previous-btn-ole-lp`,
        }}
        nextButtonProps={{
          'aria-label': t('Go to next page'),
          'data-analytics-id': `top-next-btn-ole-lp`,
        }}
      />
      <HUD.Items key="items">
        <HUD.Item
          eventKey="bookmarks"
          type="panel"
          title={t('Bookmarks')}
          icon={<SVG src={BookmarksIcon} />}
          activeIcon={<SVG src={BookmarksIconFilled} />}
          hasPadding
          buttonProps={{
            'aria-label': t('Toggle bookmarks panel'),
            'data-analytics-id': `hud-bookmarks-ole-lp`,
          }}
        >
          <HUDBookmarks
            isCurrentPageBookmarked={courseStore.isCurrentPageBookmarked}
            onBookmarkChange={() => {
              courseStore.handleCurrentPageAsBookmarked();
            }}
            onBookmarkRemove={(id: string) => {
              courseStore.handleBookmark(id, undefined);
            }}
            bookmarks={bookmarks}
            renderBookmarkLink={({ id, name }) => {
              if (id === pageSlug || !id) {
                return <p key={name}>{name}</p>;
              }
              return (
                <Link
                  key={id}
                  href
                  routeName="courses:page"
                  data-analytics-id="hud-bookmarks-add-item-ole-lp"
                  routeParams={{
                    course: (route as any)?.params?.course,
                    page: id,
                  }}
                >
                  {name}
                </Link>
              );
            }}
          />
        </HUD.Item>
        {canShowVideoPlayer && (
          <HUD.Item
            eventKey="video-player"
            type="toggle"
            title={t('Toggle video player')}
            icon={
              <SVG src={isVideoPlayerEnabled ? VideoIconFilled : VideoIcon} />
            }
            buttonProps={{
              'aria-label': 'Toggle course video player',
              'data-analytics-id': `hud-video-toggle-ole-lp`,
            }}
            isEnabled={isVideoPlayerEnabled}
            onChange={onVideoPlayerChange}
          />
        )}
        {!isFreeToolbar && (
          <HUD.Item
            eventKey="feedback"
            type="toggle"
            title={t('Feedback')}
            icon={
              <SVG
                src={isFeedbackDrawerOpen ? FeedbackIconFilled : FeedbackIcon}
              />
            }
            buttonProps={{
              'aria-label': 'Send feedback',
              'data-analytics-id': `hud-feedback-ole-lp`,
              role: 'link',
            }}
            isEnabled={isFeedbackDrawerOpen}
            onChange={() => {
              courseStore.openFeedbackDrawer();
            }}
          />
        )}
        {isBookshelfEnabled && canShowBookshelf && (
          <HUD.Item
            eventKey="bookshelf"
            type="custom"
            title={t('Access Bookshelf')}
            icon={<SVG src={BookshelfIcon} />}
            buttonProps={{
              'aria-label': 'Access course Bookshelf',
              'data-analytics-id': `hud-bookshelf-ole-lp`,
              role: 'link',
            }}
            onChange={() => {
              window.open(courseStore.bookshelfURL, '_blank');
            }}
          />
        )}
        <HUD.Item
          eventKey="settings"
          type="panel"
          title={t('Settings')}
          icon={<SVG src={SettingsIcon} />}
          activeIcon={<SVG src={SettingsIconFilled} />}
          hasPadding
          buttonProps={{
            'aria-label': t('Toggle settings panel'),
            'data-analytics-id': `hud-settings-ole-lp`,
          }}
        >
          {(contextValues) => (
            <div style={{ width: '100%', height: '100%' }}>
              <SettingsPanel
                onDirectionChange={(hudDirection) => {
                  contextValues.setDirection(hudDirection);
                }}
                currentDirection={contextValues.direction}
              />
            </div>
          )}
        </HUD.Item>
      </HUD.Items>
    </HUD>
  );
};

export default withErrorBoundary(
  observer(CourseHUD),
  ComponentError,
  handleError,
);
